import React from 'react';
import './App.css';
import './css/styles.css';
import Commerce from './components/Commerce.jsx'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Commerce />
      </header>
    </div>
  );
}

export default App;
